<template>
  <v-container fluid class="relative header-space container">
    <div>
      <v-row class="mt-5">
        <div class="col col-12">
          <h1>Salesforce</h1>
          <p>Datensätze können hier für die Synchronisierung mit Salesforce markiert werden (ohne Erstellung einer Freigabe).<br>
            Markierte Daten werden zu regelmässigen Zeiten automatisch mit Salesforce synchronisiert.</p>
        </div>
        <div class="col col-12">
          <p>
            <small><b>Voraussetzungen:</b> Es werden lediglich Datensätze markiert die bereits verifiziert und nicht manuell exkludiert wurden.</small><br>
          </p>
        </div>
      </v-row>
      <v-row class="mt-5">
        <div v-show="initiated" class="col-sm-12">
          <v-tabs
            background-color="primary"
            center-active
            dark
            grow
          >
            <v-tab key="default" v-on:click="resetFilters">
              <v-icon class="mr-3">mdi-check-all</v-icon>Default
            </v-tab>
            <v-tab key="sample" v-on:click="resetFilters">
              <v-icon class="mr-3">mdi-format-list-checkbox</v-icon>Sample
            </v-tab>

            <v-tab-item key="default">
              <v-row class="px-4 py-4">
                <div class="col-12 col-md-3 col">
                  <v-select
                    v-model="selectedRankingGroupShortCode"
                    item-text="name"
                    item-value="shortCode"
                    :items="rankingGroups"
                    label="Projekt"></v-select>
                </div>
                <div class="col-12 col-md-3 col">
                  <v-select
                    v-model="selectedToplistIds"
                    item-text="value"
                    item-value="id"
                    :items="toplistLabels"
                    label="Topliste(n)"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.value }}</span>
                      </v-chip>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                      >
                    (+{{ selectedToplistIds.length - 1 }} weitere)
                  </span>
                    </template>
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="toggleToplistSelection"
                      >
                        <v-list-item-action>
                          <v-icon :color="selectedToplistIds.length > 0 ? 'indigo darken-4' : ''">
                            {{ toggleIcon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Alles togglen
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-md-2 col">
                  <v-select
                    v-model="selectedPeriodName"
                    :disabled="selectedRankingGroupShortCode === null"
                    :items="periodNames"
                    label="Jahrgang"></v-select>
                </div>
                <div class="col-12 col-md-2 col">
                  <v-checkbox
                    v-model="onlyUnsynced"
                    label="Nur nie synchronisierte"
                  ></v-checkbox>
                </div>
                <div class="col-12 col-md-2 col">
                  <v-btn
                    v-on:click="markForSync"
                    outlined class="blue--text text--darken-3 mt-4"
                    :disabled="selectedPeriodName === null"
                    block>
                    Markieren
                  </v-btn>
                </div>
              </v-row>
            </v-tab-item>
            <v-tab-item key="sample">
              <v-row class="px-4 py-4">
                <v-col class="col-12 col-md-9">
                  <v-select
                    v-model="selectedSample"
                    label="Sample auswählen"
                    :items="samples"
                    item-text="rawDataReference"
                    item-value="id">
                  </v-select>
                </v-col>
                <v-col class="col-12 col-md-3">
                  <v-btn v-on:click="markSampleForSync" :disabled="selectedSample === null" color="primary" block class="mt-3 mb-3">Markieren</v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </div>
      </v-row>
      <v-row class="mt-5">
        <div class="col col-12">
          <h1>Übersicht markierter Entities</h1>
          <p>Zeigt an wieviele Entities pro Typ und insgesamt markiert sind.</p>
        </div>
        <div class="col col-12 text-right">
          <v-btn class="ml-3 mb-3 primary" v-on:click="dialogClearAll = true"><v-icon class="mr-1">mdi-close-circle-outline</v-icon>Clear Sync Requests</v-btn>
          <p class="small">Hier kann die Markierung zur Synchronization von allen Entitäten entfernt werden.</p>
        </div>
        <div class="col col-12">
          <v-card class="mb-6">
            <v-simple-table v-if="!loading">
              <thead>
              <tr>
                <th class="text-left column-width" style="min-width: 250px;">Name</th>
                <th class="text-left column-width">Wert</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="metric in syncStatus" v-bind:key="metric.name">
                <td>{{ metric.name }}</td>
                <td>{{ metric.value }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </div>
      </v-row>
      <v-row class="mt-5">
        <v-col sm="12">
          <h1>API Connection</h1>
          <p>Auf Knopfdruck wird die API Verbindung zu Salesforce getestet indem ein Testrequest abgeschickt wird, welcher bei Erfolg die aktuellen API Limits zurückgibt.</p>
          <v-btn class="mb-3 primary" v-on:click="checkConnection"><v-icon class="mr-1">mdi-api</v-icon>Test connection</v-btn>
          <p v-if="connectionStatus !== null">{{ connectionStatus }}</p>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="dialogResult" max-width="500px">
      <v-card>
        <v-card-title class="headline">Markierung abgeschlossen</v-card-title>
        <v-card-text>Es wurden {{ this.countMarked }} Entities für die Synchronisierung mit Salesforce markiert.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeResult">Ok</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogClearAll" max-width="500px">
      <v-card>
        <v-card-title class="headline">Achtung!</v-card-title>
        <v-card-text>Dieser Vorgang entfernt die Markierung zur Synchronization von allen Entities!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialogClearAll = false">Abbrechen</v-btn>
          <v-btn outlined color="red" @click="clearAllSyncMarksConfirm">Bestätigen</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { find } from 'lodash'

export default {
  data: () => ({
    loading: false,
    countMarked: -1,
    initiated: false,
    dialogResult: false,
    dialogClearAll: false,
    rankingGroups: [],
    periodNames: [],
    toplistLabels: [],
    samples: [{ id: null, rawDataReference: null }],
    selectedSample: null,
    selectedRankingGroupShortCode: null,
    selectedPeriodName: null,
    selectedToplistIds: [],
    onlyUnsynced: false,
    syncStatus: [],
    connectionStatus: null
  }),
  async created () {
    await this.$auth.waitForAuth()
    if (!this.$auth.isAuthenticated) {
      await this.$router.push('/')
      return
    }

    const calls = []
    // promise of loading ranking groups from backend
    const rankingGroupPromise = this.$http.get('/ranking_group/list')
      .then((response) => {
        this.rankingGroups = response.data
      })
    calls.push(rankingGroupPromise)
    // promise of loading periods from backend
    const periodPromise = this.$http.get('/period/list')
      .then((response) => {
        this.periodNames = response.data.map(period => period.name)
        this.periods = response.data
      })
    calls.push(periodPromise)
    // Load samples
    const payload = {
      query: '{ samples { id, rawDataReference } }'
    }
    const samplesPromise = this.$http.post('/warehouse-graphql', payload)
      .then((response) => {
        this.samples = response.data.data.samples
      })
    calls.push(samplesPromise)
    // promise of loading sync status overview
    const statusPromise = this.getSyncStatus()
    calls.push(statusPromise)
    // Collects all promises
    await Promise.all(calls)
    this.initiated = true
  },
  methods: {
    resetFilters: async function () {
      this.selectedRankingGroupShortCode = null
      this.selectedPeriodName = null
      this.selectedToplistIds = []
      this.onlyUnsynced = false
      this.selectedSample = null
    },
    getSyncStatus: async function () {
      const response = await this.$http.get('/salesforce/status')
      this.syncStatus = response.data
    },
    markForSync: async function () {
      const payload = {
        periodId: this.selectedPeriod.id,
        rankingGroupId: this.selectedRankingGroup.id,
        toplistLabelIds: this.selectedToplistIds,
        onlyUnsynced: this.onlyUnsynced,
        sampleId: this.selectedSample
      }
      const response = await this.$http.post('/salesforce/sync', payload)
      this.countMarked = response.data.split(' ').pop()
      await this.getSyncStatus()
      await this.resetFilters()
      this.dialogResult = true
    },
    markSampleForSync: async function () {
      const response = await this.$http.post('/salesforce/sync-sample/' + this.selectedSample)
      this.countMarked = response.data
      await this.getSyncStatus()
      await this.resetFilters()
      this.dialogResult = true
    },
    clearAllSyncMarksConfirm: async function () {
      this.dialogClearAll = false
      this.loading = true
      await this.$http.post('/salesforce/clear-sync-requests')
      await this.getSyncStatus()
      this.loading = false
    },
    checkConnection: async function () {
      await this.$http.get('/salesforce/connection')
        .then((response) => {
          this.connectionStatus = response.data
        })
    },
    toggleToplistSelection () {
      this.$nextTick(() => {
        if (this.selectedAllToplists) {
          this.selectedToplistIds = []
        } else {
          this.selectedToplistIds = []
          this.toplistLabels.forEach(element => this.selectedToplistIds.push(element.id))
        }
        console.log(this.selectedToplistIds)
      })
    },
    closeResult () {
      this.dialogResult = false
    }
  },
  computed: {
    selectedRankingGroup () {
      if (this.selectedRankingGroupShortCode === null) {
        return null
      }
      return find(this.rankingGroups, { shortCode: this.selectedRankingGroupShortCode })
    },
    selectedAllToplists () {
      return this.selectedToplistIds.length === this.toplistLabels.length
    },
    selectedSomeToplists () {
      return this.selectedToplistIds.length > 0 && !this.selectedAllToplists
    },
    toggleIcon () {
      if (this.selectedAllToplists) return 'mdi-close-box'
      if (this.selectedSomeToplists) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    selectedPeriod () {
      if (this.selectedPeriodName === null) {
        return null
      }
      return find(this.periods, { name: this.selectedPeriodName })
    }
  },
  watch: {
    selectedRankingGroupShortCode: function (newSelection, oldSelection) {
      if (
        newSelection !== oldSelection &&
        newSelection !== null
      ) {
        this.$http.get('/toplist_label/list?rgShortCode=' + newSelection).then((response) => {
          this.toplistLabels = []
          this.toplistLabels = this.toplistLabels.concat(response.data.list)
        })
      }
    }
  }
}
</script>
